.advantages {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px 30px;

  @media (max-width: $vp-large) {
    padding: 63px 40px 30px;
  }

  @media (max-width: $vp-tablet) {
    padding: 39px 44px 30px;
    align-items: flex-start;
  }

  &__bg {
    z-index: -1;
    position: absolute;
    left: 0;
    top: -462px;
    width: 100%;
    height: 2208px;
    background: url('../../img/bg-light-3.png?as=webp&quality=100&width=220') calc(50% + 334px) 0 / 2208px 2208px no-repeat;
    
    @media (max-width: $vp-tablet) {
      top: -80px;
      height: 1472px;
      background: url('../../img/bg-light-3.png?as=webp&quality=80&width=220') calc(50% + 158px) 0 / 1472px 1472px no-repeat;
    }
  }

  &__title {
    margin: 0 0 53px;
    max-width: 768px;
    text-align: center;

    @media (max-width: $vp-large) {
      max-width: unset;
    }

    @media (max-width: $vp-tablet) {
      margin-bottom: 48px;
      font-size: 22px;
      text-align: start;
    }
  }

  &__list {
    display: flex;
    max-width: 1110px;
    justify-content: space-between;

    @media (max-width: $vp-large) {
      max-width: 930px;
    }

    @media (max-width: $vp-tablet) {
      flex-direction: column;
      gap: 20px;
      width: unset;
      justify-content: unset;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 22%;

    @media (max-width: $vp-tablet) {
      width: auto;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 80px;
    height: 77px;
    margin-bottom: 20px;

    @media (max-width: $vp-tablet) {
      width: 50px;
      height: 48px;
      margin-bottom: 0;
      margin-right: 20px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__text {

  }

  &__name {
    margin: 0;
    font-weight: 500;
    opacity: 0.8;
    background-color: var(--color-light-slate-blue);
    background-image: var(--gradient-orange-purple);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__description {
    margin: 0;
    font-weight: 300;
  }

}
