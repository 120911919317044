.doctors {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px 0 0;

  &__bg {
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: -670px;
    width: 100%;
    height: 1757px;
    background: 
      url('../../img/bg-light-7.png?as=webp&quality=90&width=175') calc(50% - 437px) 0 / 1757px 1757px no-repeat;
  
    @media (max-width: $vp-tablet) {
      bottom: -396px;
      height: 1173px;
      background:
        url('../../img/bg-light-7-mobile.png?as=webp&quality=90&width=120') calc(50% - 289px) 0 / 1207px 1173px no-repeat;
    }
  }

  &__text {
    max-width: 770px;
    margin: 0 0 71px;
    padding: 0 30px;
    text-align: center;
    opacity: 0.8;

    @media (max-width: $vp-tablet) {
      text-align: start;
      padding: 0 44px;
    }
  }
  
  &__carousel {
    width: 100%;
  }
}

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 78px 30px 55px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    width: 100%;

    @media (max-width: 1380px) {
      justify-content: flex-start;
      max-width: 1100px;
    }

    @media (max-width: $vp-large) {
      justify-content: flex-start;
      max-width: 930px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 240px;
    padding: 105px 21px 63px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 0px 20px 60px 0px rgba(98, 17, 80, 0.50);
  }

  &__photo {
    position: absolute;
    top: -78px;
    left: calc(50% - 75px);
    width: 150px;
    height: 150px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__name {
    margin: 0 0 10;
    font-weight: 500;
    opacity: 0.8;
  }

  &__text {
    margin: 0;
    opacity: 0.8;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  } 
}

.embla {
  overflow: hidden;

  &__container {
    display: flex;
  }
  &__slide {
    // flex: 0 0 100%;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 0;
  }
}
