@font-face {
  font-family: 'Stem';
  src: url('../../fonts/Stem-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../../fonts/Stem-ExtraLight.otf') format("opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../../fonts/Stem-Hairline.otf') format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../../fonts/Stem-Regular.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../../fonts/Stem-Italic.otf') format("opentype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../../fonts/Stem-Bold.otf') format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Stem';
  src: url('../../fonts/Stem-Medium.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
