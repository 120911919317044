.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px;

  @media (max-width: $vp-tablet) {
    min-height: 80px;
    padding: 23px 24px 14px;
  }

  .logo {
    display: block;
    margin-right: 40px;
    width: 80px;
    height: 34px;

    img {
      width: 100%;
      height: auto;
    }

    @media (max-width: $vp-tablet) {
      margin-right: auto;
      width: 100px;
      height: 43px;
    }
  }

  .main-nav {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1110px;

    @media (max-width: $vp-large) {
      max-width: 930px;
    }

    @media (max-width: $vp-tablet) {
      width: 100%;
      justify-content: space-between;
    }
  }

  .main-nav__toggle {
    display: none;
    width: 27px;
    height: 27px;
    margin: 0;
    font-size: 0;
    background-color: transparent;
    border: none;
    opacity: 0.4;
    transition: opacity 0.3s ease, background-image 1s ease;
    background-image: url('../../img/icon-burger.svg');
    background-position: center;
    background-repeat: no-repeat;
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      display: block;
      visibility: visible;
      opacity: 1;
    }

    @media (max-width: $vp-tablet) {
      display: block;
    }
  }

  .main-nav.is-open .main-nav__toggle {
    display: block;
    visibility: visible;
    opacity: 1;
    background-image: url('../../img/icon-cross.svg');
  }

  .main-nav__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: visibility 0.3s ease, opacity 0.3s ease;

    @media (max-width: $vp-tablet) {
      z-index: 1000;
      visibility: hidden;
      position: absolute;
      top: 57px;
      left: -24px;
      flex-direction: column;
      width: 100vw;
      height: calc(100vh - 80px);
      padding-top: 30px;
      background-color: var(--color-black);
      opacity: 0;
    }
  }

  .main-nav.is-open .main-nav__list {
    @media (max-width: $vp-tablet) {
      visibility: visible;
      opacity: 1;
    }
  }

  .main-nav__item {
    &:not(:last-child) {
      margin-right: 20px;
    }

    @media (max-width: $vp-tablet) {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 20px;
      } 
    }
  }

  .main-nav__link {
    font-weight: 500;
    line-height: 18px;
    text-decoration: none;
    color: var(--color-white-default);
    opacity: 0.4;

    &:hover,
    &:active {
      opacity: 1;
    }

    @media (max-width: $vp-tablet) {
      font-size: 20px;
      line-height: normal;
    }
  }
}
