:root {
  --color-white-default: #fff;
  --color-black: #000;
  --color-black-russian: #212227;
  --color-tuna: #474b50;
  --color-spun-pearl: #a0a0a2;
  --color-light-slate-blue: #9e5fff;
  --color-froly: #fd777a;
  --color-: #ff009980;
  --gradient-orange-purple: linear-gradient(169deg, #f77 0%, #9e5fff 100%);
  --gradient-red-purple: linear-gradient(332deg, #7742e8 0%, #ff003d 100%);
  --gradient-purple-red: linear-gradient(9deg, #7742e8 0%, #ff003d 100%);
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

:is([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: Stem;
  src: url("../Stem-Light.2035b790.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Stem;
  src: url("../Stem-ExtraLight.b47a64ae.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Stem;
  src: url("../Stem-Hairline.95e34603.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Stem;
  src: url("../Stem-Regular.c54ccf1b.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Stem;
  src: url("../Stem-Italic.68a336f2.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Stem;
  src: url("../Stem-Bold.b97e70c0.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Stem;
  src: url("../Stem-Medium.478ed5d7.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.button {
  text-align: center;
  border-radius: 40px;
  min-width: 200px;
  padding: 6px 40px;
  font-weight: 500;
  line-height: 32px;
  text-decoration: none;
  display: block;
  position: relative;
}

.button--primary {
  color: #fff9;
  background: var(--gradient-red-purple);
  z-index: 1;
  border: none;
  transition: box-shadow .3s;
  box-shadow: 0 0 50px #e4117780;
}

.button--primary:hover, .button--primary:active {
  color: var(--color-white-default);
  box-shadow: 0 0 50px #f09, 0 5px 50px #ff009940;
}

.button--primary:hover:before, .button--primary:active:before {
  opacity: 1;
}

.button--primary:before {
  content: "";
  opacity: 0;
  background: var(--gradient-purple-red);
  z-index: -1000;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.button--secondary {
  color: #fff9;
  background-color: #0000;
  background-image: var(--gradient-purple-red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: box-shadow .3s, color .3s;
}

.button--secondary:hover, .button--secondary:active {
  color: var(--color-white-default);
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
  box-shadow: 0 0 50px #f09, 0 5px 50px #ff009940;
}

.button--secondary:before {
  content: "";
  background: var(--gradient-purple-red) border-box;
  -webkit-mask-composite: xor;
  border: 3px solid #0000;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0);
  -webkit-mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  -webkit-mask-clip: padding-box, border-box;
  -webkit-mask-origin: padding-box, border-box;
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  -webkit-mask-source-type: auto, auto;
  mask-mode: match-source, match-source;
}

@media (width <= 768px) {
  .button {
    min-width: 250px;
  }
}

.header {
  justify-content: center;
  align-items: center;
  padding: 23px;
  display: flex;
}

@media (width <= 768px) {
  .header {
    min-height: 80px;
    padding: 23px 24px 14px;
  }
}

.header .logo {
  width: 80px;
  height: 34px;
  margin-right: 40px;
  display: block;
}

.header .logo img {
  width: 100%;
  height: auto;
}

@media (width <= 768px) {
  .header .logo {
    width: 100px;
    height: 43px;
    margin-right: auto;
  }
}

.header .main-nav {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1110px;
  display: flex;
  position: relative;
}

@media (width <= 1200px) {
  .header .main-nav {
    max-width: 930px;
  }
}

@media (width <= 768px) {
  .header .main-nav {
    justify-content: space-between;
    width: 100%;
  }
}

.header .main-nav__toggle {
  opacity: .4;
  appearance: none;
  background-color: #0000;
  background-image: url("../icon-burger.bf11049b.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 27px;
  height: 27px;
  margin: 0;
  font-size: 0;
  transition: opacity .3s, background-image 1s;
  display: none;
}

.header .main-nav__toggle:hover, .header .main-nav__toggle:focus, .header .main-nav__toggle:active, .header .main-nav__toggle:focus-visible {
  visibility: visible;
  opacity: 1;
  display: block;
}

@media (width <= 768px) {
  .header .main-nav__toggle {
    display: block;
  }
}

.header .main-nav.is-open .main-nav__toggle {
  visibility: visible;
  opacity: 1;
  background-image: url("../icon-cross.f710f19a.svg");
  display: block;
}

.header .main-nav__list {
  flex-direction: row;
  align-items: center;
  transition: visibility .3s, opacity .3s;
  display: flex;
}

@media (width <= 768px) {
  .header .main-nav__list {
    z-index: 1000;
    visibility: hidden;
    background-color: var(--color-black);
    opacity: 0;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 80px);
    padding-top: 30px;
    position: absolute;
    top: 57px;
    left: -24px;
  }

  .header .main-nav.is-open .main-nav__list {
    visibility: visible;
    opacity: 1;
  }
}

.header .main-nav__item:not(:last-child) {
  margin-right: 20px;
}

@media (width <= 768px) {
  .header .main-nav__item:not(:last-child) {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.header .main-nav__link {
  color: var(--color-white-default);
  opacity: .4;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
}

.header .main-nav__link:hover, .header .main-nav__link:active {
  opacity: 1;
}

@media (width <= 768px) {
  .header .main-nav__link {
    font-size: 20px;
    line-height: normal;
  }
}

.footer {
  background-color: var(--color-black-russian);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.footer__containter {
  flex-flow: column wrap;
  align-content: space-between;
  width: 100%;
  max-width: 1110px;
  max-height: 445px;
  padding: 48px 30px 129px;
  display: flex;
}

@media (width <= 1200px) {
  .footer__containter {
    max-width: 930px;
  }
}

@media (width <= 768px) {
  .footer__containter {
    max-height: initial;
    flex-wrap: nowrap;
    max-width: 1110px;
    padding: 48px 25px 69px 39px;
  }
}

.footer__info {
  order: -2;
  width: 290px;
}

@media (width <= 768px) {
  .footer__info {
    order: initial;
    width: auto;
    max-width: 325px;
  }
}

.footer__tel {
  letter-spacing: .25px;
  color: var(--color-white-default);
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 500;
  line-height: 32px;
  text-decoration: none;
  display: block;
}

.footer__text {
  letter-spacing: .14px;
  margin: 0 0 20px;
  line-height: 22px;
}

.footer__nav {
  min-height: 258px;
  margin-bottom: 26px;
}

@media (width <= 768px) {
  .footer__nav {
    min-height: initial;
    margin-top: 6px;
    margin-bottom: 60px;
  }
}

.footer__nav-item:not(:last-child) {
  margin-bottom: 10px;
}

.footer__nav-link {
  letter-spacing: .14px;
  color: var(--color-spun-pearl);
  text-decoration: none;
  transition: color .3s;
}

.footer__nav-link:hover, .footer__nav-link:active {
  color: var(--color-white-default);
}

.footer__sk {
  order: -1;
  width: 120px;
  height: 34px;
}

.footer__sk img {
  width: 100%;
  height: auto;
}

@media (width <= 768px) {
  .footer__sk {
    order: initial;
    margin-bottom: 20px;
  }
}

.footer__social {
  width: 226px;
  margin-bottom: 26px;
}

@media (width <= 768px) {
  .footer__social {
    width: auto;
    margin-bottom: 79px;
  }
}

.footer__social-list {
  display: flex;
}

.footer__social-item:not(:last-child) {
  margin-right: 13px;
}

.footer__social-link {
  background-color: var(--color-tuna);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  width: 34px;
  height: 31px;
  font-size: 0;
  transition: background-color .3s;
  display: block;
}

.footer__social-link:hover, .footer__social-link:active {
  background-color: #ff009980;
}

.footer__social-link--vk {
  background-image: url("../icon-vk.11619207.svg");
  background-size: 27px 14px;
}

.footer__social-link--ok {
  background-image: url("../icon-ok.c64d238d.svg");
  background-size: 31px 33px;
}

.footer__social-link--tg {
  background-image: url("../icon-telegram.33cb8f14.svg");
  background-size: 35px 35px;
}

.footer__security {
  width: 226px;
}

@media (width <= 768px) {
  .footer__security {
    width: auto;
    max-width: 325px;
  }
}

.footer__security-text {
  color: var(--color-spun-pearl);
  letter-spacing: .11px;
  font-size: 11px;
  line-height: normal;
}

.footer__security-text:not(:last-child) {
  margin-bottom: 26px;
}

.footer__security-text p {
  margin: 0;
}

.footer__security-text p:not(:last-child) {
  margin-bottom: 6px;
}

.footer__security-text a {
  color: var(--color-spun-pearl);
  text-decoration: none;
  transition: color .3s;
}

.footer__security-text a:hover, .footer__security-text a:active {
  color: var(--color-white-default);
}

.preview {
  padding: 43px 0 30px;
}

@media (width <= 768px) {
  .preview {
    padding: 40px 44px 30px;
  }
}

.preview__container {
  flex-direction: column;
  align-items: center;
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
}

@media (width <= 1200px) {
  .preview__container {
    max-width: 930px;
  }
}

@media (width <= 768px) {
  .preview__container {
    max-width: 327px;
  }
}

.preview__title {
  text-align: center;
  background-color: var(--color-light-slate-blue);
  background-image: var(--gradient-orange-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 920px;
  margin: 0 0 30px;
  font-size: 40px;
  font-weight: 500;
  line-height: 42px;
}

@media (width <= 1200px) {
  .preview__title {
    max-width: 692px;
    margin-bottom: 14px;
  }
}

@media (width <= 768px) {
  .preview__title {
    text-align: start;
    max-width: 100%;
    margin-bottom: 25px;
    font-size: 36px;
  }
}

.preview__subtitle {
  text-align: center;
  color: #fffc;
  max-width: 592px;
  margin: 0 0 449px;
}

@media (width <= 1200px) {
  .preview__subtitle {
    max-width: 448px;
    margin-bottom: 391px;
  }
}

@media (width <= 768px) {
  .preview__subtitle {
    text-align: start;
    margin-bottom: 307px;
  }
}

.preview__buttons {
  justify-content: center;
  gap: 20px;
  display: flex;
}

@media (width <= 768px) {
  .preview__buttons {
    flex-direction: column;
    gap: 25px;
  }
}

.legend {
  padding: 65px 30px 30px;
  position: relative;
}

@media (width <= 768px) {
  .legend {
    padding: 39px 24px 30px;
  }
}

.legend__bg {
  z-index: -1;
  background: url("../bg-light-2.889ea30f.webp") calc(50% + 306px) 25px / 1284px 1284px no-repeat, url("../bg-light-1.dc3f2886.webp") calc(50% - 262px) 0 / 1368px 1101px no-repeat;
  width: 100%;
  height: 1309px;
  position: absolute;
  top: -434px;
  left: 0;
}

@media (width <= 768px) {
  .legend__bg {
    background: url("../bg-light-2.f125b290.webp") calc(50% + 105px) 98px / 1035px 1035px no-repeat, url("../bg-light-1.3ad242e4.webp") calc(50% - 80px) 0 / 900px 724px no-repeat;
    height: 1382px;
    top: -279px;
  }
}

.legend__text-block {
  background: #ffffff1a;
  border-radius: 30px;
  max-width: 1110px;
  margin: 0 auto;
  padding: 44px 65px 45px;
  box-shadow: 0 20px 60px #62115080;
}

@media (width <= 1200px) {
  .legend__text-block {
    max-width: 930px;
    padding: 53px 80px;
  }
}

@media (width <= 768px) {
  .legend__text-block {
    max-width: inherit;
    padding: 64px 30px 71px;
  }
}

.legend__text {
  color: #fffc;
  margin: 0;
}

.advantages {
  flex-direction: column;
  align-items: center;
  padding: 60px 40px 30px;
  display: flex;
  position: relative;
}

@media (width <= 1200px) {
  .advantages {
    padding: 63px 40px 30px;
  }
}

@media (width <= 768px) {
  .advantages {
    align-items: flex-start;
    padding: 39px 44px 30px;
  }
}

.advantages__bg {
  z-index: -1;
  background: url("../bg-light-3.e4b1a461.webp") calc(50% + 334px) 0 / 2208px 2208px no-repeat;
  width: 100%;
  height: 2208px;
  position: absolute;
  top: -462px;
  left: 0;
}

@media (width <= 768px) {
  .advantages__bg {
    background: url("../bg-light-3.fe1747e5.webp") calc(50% + 158px) 0 / 1472px 1472px no-repeat;
    height: 1472px;
    top: -80px;
  }
}

.advantages__title {
  text-align: center;
  max-width: 768px;
  margin: 0 0 53px;
}

@media (width <= 1200px) {
  .advantages__title {
    max-width: unset;
  }
}

@media (width <= 768px) {
  .advantages__title {
    text-align: start;
    margin-bottom: 48px;
    font-size: 22px;
  }
}

.advantages__list {
  justify-content: space-between;
  max-width: 1110px;
  display: flex;
}

@media (width <= 1200px) {
  .advantages__list {
    max-width: 930px;
  }
}

@media (width <= 768px) {
  .advantages__list {
    width: unset;
    gap: 20px;
    justify-content: unset;
    flex-direction: column;
  }
}

.advantages__item {
  flex-direction: column;
  width: 22%;
  display: flex;
}

@media (width <= 768px) {
  .advantages__item {
    flex-direction: row;
    align-items: flex-start;
    width: auto;
  }
}

.advantages__icon {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 77px;
  margin-bottom: 20px;
  display: flex;
}

@media (width <= 768px) {
  .advantages__icon {
    width: 50px;
    height: 48px;
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.advantages__icon img {
  width: 100%;
  height: auto;
}

.advantages__name {
  opacity: .8;
  background-color: var(--color-light-slate-blue);
  background-image: var(--gradient-orange-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-weight: 500;
}

.advantages__description {
  margin: 0;
  font-weight: 300;
}

.description {
  flex-direction: column;
  align-items: center;
  padding: 96px 30px 30px;
  display: flex;
  position: relative;
}

@media (width <= 768px) {
  .description {
    padding: 70px 24px 30px;
  }
}

.description__bg-1 {
  z-index: -1;
  background: url("../bg-light-5.f16468fa.webp") calc(50% - 249px) 229px / 1284px 1284px no-repeat, url("../bg-light-4.889ea30f.webp") calc(50% + 234px) 0 / 1284px 1284px no-repeat;
  width: 100%;
  height: 1514px;
  position: absolute;
  top: -106px;
  left: 0;
}

@media (width <= 768px) {
  .description__bg-1 {
    background: url("../bg-light-5-mobile.a222cec3.webp") calc(50% - 84px) 240px / 953px 724px no-repeat, url("../bg-light-4.9d9860e9.webp") calc(50% + 110px) 0 / 1044px 1044px no-repeat;
    height: 1044px;
    top: 130px;
  }
}

.description__bg-2 {
  z-index: -1;
  background: url("../bg-light-6.c490b806.webp") calc(50% + 274px) 0 / 1757px 1757px no-repeat;
  width: 100%;
  height: 1757px;
  position: absolute;
  bottom: -849px;
  left: 0;
}

@media (width <= 768px) {
  .description__bg-2 {
    background: url("../bg-light-6.1c8c0c26.webp") calc(50% + 289px) 0 / 1246px 1246px no-repeat;
    height: 1246px;
    bottom: -709px;
  }
}

.description__text-block {
  max-width: 768px;
  margin-bottom: 47px;
}

@media (width <= 768px) {
  .description__text-block {
    padding: 0 20px;
  }
}

.description__title {
  text-align: center;
  margin: 0 0 29px;
}

@media (width <= 768px) {
  .description__title {
    text-align: start;
    margin: 0 0 35px;
  }
}

.description__text {
  text-align: center;
  opacity: .8;
}

.description__text p {
  margin: 0;
}

.description__text p:not(:last-child) {
  margin: 0 0 15px;
}

@media (width <= 768px) {
  .description__text {
    text-align: start;
  }
}

.description__content {
  width: 100%;
  max-width: 1110px;
}

@media (width <= 1200px) {
  .description__content {
    max-width: 930px;
  }
}

.content {
  -webkit-user-select: none;
  user-select: none;
  background: #ffffff1a;
  border-radius: 30px;
  flex-direction: column;
  padding: 84px 80px 79px;
  display: flex;
  box-shadow: 0 20px 60px #62115080;
}

@media (width <= 768px) {
  .content {
    padding: 66px 20px 54px;
  }
}

.content__list {
  flex-direction: column;
  margin: 27px 0 72px;
  display: flex;
}

@media (width <= 768px) {
  .content__list {
    margin: 13px 0 59px;
  }
}

.content__item {
  border-bottom: 1px solid #fff6;
  width: 100%;
  max-height: 52px;
  transition: max-height .5s;
}

.content__item:not(:last-child) {
  margin-bottom: 30px;
}

.content__item.is-open {
  max-height: 1000px;
}

.content__item-header {
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  position: relative;
}

.content__item-header:after {
  content: "";
  background-image: url("../icon-arrow.aa486b42.svg");
  width: 22px;
  height: 12px;
  transition: transform .3s;
  position: absolute;
  top: 9px;
  right: 0;
}

.is-open .content__item-header:after {
  transform: rotate(180deg);
}

.content__icon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.content__name {
  color: var(--color-white-default);
  opacity: .8;
  margin: 0 auto 0 0;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.content__item-body {
  justify-content: space-between;
  max-height: 0;
  margin-bottom: 10px;
  transition: max-height .5s;
  display: flex;
  overflow: hidden;
}

.is-open .content__item-body {
  max-height: inherit;
  opacity: 1;
}

@media (width <= 768px) {
  .content__item-body {
    flex-direction: column;
  }
}

.content__description {
  opacity: 0;
  transform-origin: top;
  margin: 0 80px 0 10px;
  padding-left: 15px;
  font-weight: 200;
  list-style-type: "⋅  ";
  transition: opacity 2s;
}

.is-open .content__description {
  opacity: 1;
}

.content__description li {
  opacity: .8;
}

.content__description li:not(:last-child) {
  margin-bottom: 3px;
}

@media (width <= 768px) {
  .content__description {
    margin: 0 0 20px 7px;
  }
}

.content__footer {
  align-items: flex-end;
  display: flex;
}

@media (width <= 768px) {
  .content__footer {
    flex-wrap: wrap;
  }
}

.content__footer-text {
  opacity: .8;
  margin: 0 auto 0 0;
}

@media (width <= 768px) {
  .content__footer-text {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 20px;
  }
}

.content__old-price {
  opacity: .8;
  margin: 0 20px 0 0;
  text-decoration: line-through;
}

.content__price {
  opacity: .8;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.content__example {
  color: var(--color-froly);
  opacity: 0;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 136px;
  text-decoration-line: underline;
  transition: color .3s, opacity 1.5s;
  display: flex;
}

.content__example:hover, .content__example:active {
  color: var(--color-white-default);
}

.is-open .content__example {
  opacity: 1;
}

.content__example span {
  opacity: .8;
}

.content__image {
  margin-bottom: 2px;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.doctors {
  flex-direction: column;
  align-items: center;
  padding: 46px 0 0;
  display: flex;
  position: relative;
}

.doctors__bg {
  z-index: -1;
  background: url("../bg-light-7.5c450247.webp") calc(50% - 437px) 0 / 1757px 1757px no-repeat;
  width: 100%;
  height: 1757px;
  position: absolute;
  bottom: -670px;
  left: 0;
}

@media (width <= 768px) {
  .doctors__bg {
    background: url("../bg-light-7-mobile.3006b2f9.webp") calc(50% - 289px) 0 / 1207px 1173px no-repeat;
    height: 1173px;
    bottom: -396px;
  }
}

.doctors__text {
  text-align: center;
  opacity: .8;
  max-width: 770px;
  margin: 0 0 71px;
  padding: 0 30px;
}

@media (width <= 768px) {
  .doctors__text {
    text-align: start;
    padding: 0 44px;
  }
}

.doctors__carousel {
  width: 100%;
}

.carousel {
  flex-direction: column;
  align-items: center;
  padding: 78px 30px 55px;
  display: flex;
}

.carousel__container {
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  display: flex;
}

@media (width <= 1380px) {
  .carousel__container {
    justify-content: flex-start;
    max-width: 1100px;
  }
}

@media (width <= 1200px) {
  .carousel__container {
    justify-content: flex-start;
    max-width: 930px;
  }
}

.carousel__item {
  background: #ffffff1a;
  border-radius: 30px;
  flex-direction: column;
  width: 240px;
  padding: 105px 21px 63px;
  display: flex;
  position: relative;
  box-shadow: 0 20px 60px #62115080;
}

.carousel__photo {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -78px;
  left: calc(50% - 75px);
}

.carousel__photo img {
  width: 100%;
  height: auto;
}

.carousel__name {
  opacity: .8;
  margin: 0 0 10px;
  font-weight: 500;
}

.carousel__text {
  opacity: .8;
  margin: 0;
}

.carousel__text:not(:last-child) {
  margin-bottom: 10px;
}

.embla {
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__slide {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
}

.consultation {
  flex-direction: column;
  align-items: center;
  padding: 71px 30px 244px;
  display: flex;
  position: relative;
}

@media (width <= 768px) {
  .consultation {
    padding: 37px 24px 150px;
  }
}

.consultation__bg {
  z-index: -1;
  background: url("../bg-light-9.5793dde8.webp") calc(50% + 239px) 0 / 1449px 1101px no-repeat, url("../bg-light-8.889ea30f.webp") calc(50% - 359px) 14px / 1284px 1284px no-repeat;
  width: 100%;
  height: 957px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (width <= 768px) {
  .consultation__bg {
    background: url("../bg-light-9-mobile.f27ef752.webp") calc(50% + 229px) 0 / 1112px 1082px no-repeat, url("../bg-light-8.889ea30f.webp") calc(50% - 230px) 97px / 1284px 1284px no-repeat;
    height: 948px;
    bottom: 0;
  }
}

.consultation__title {
  text-align: center;
  max-width: 768px;
  margin: 0 0 65px;
}

@media (width <= 768px) {
  .consultation__title {
    text-align: start;
    margin: 0 20px 67px;
    padding-right: 35px;
  }
}

.consultation__form {
  background: #ffffff1a;
  border-radius: 30px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 930px;
  padding: 51px 82px 10px;
  display: flex;
  position: relative;
  box-shadow: 0 20px 60px #62115080;
}

@media (width <= 768px) {
  .consultation__form {
    padding: 51px 30px 10px;
  }
}

.consultation__input-block {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: opacity .3s;
  display: flex;
}

.success .consultation__input-block {
  opacity: 0;
}

.consultation__text {
  text-align: center;
  margin: 0 0 45px;
}

@media (width <= 768px) {
  .consultation__text {
    margin: 0 0 34px;
  }
}

.consultation__input {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 405px;
  min-height: 37px;
  margin-bottom: 10px;
  padding-bottom: 8px;
  display: flex;
  position: relative;
}

@media (width <= 768px) {
  .consultation__input {
    margin-bottom: 35px;
  }
}

.consultation__input:after {
  content: "";
  background-color: #fff6;
  width: 204px;
  height: 2px;
  position: absolute;
  bottom: 9px;
  left: calc(50% - 102px);
}

.consultation__input input {
  color: #fff6;
  background-color: #0000;
  border: none;
  width: 213px;
  margin-bottom: 2px;
  font-family: Stem, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: color .7s;
  position: relative;
  bottom: 1px;
}

.consultation__input input:invalid {
  color: var(--color-froly);
}

.consultation__input input:focus-visible {
  outline: none;
}

.consultation__input input::placeholder {
  opacity: .4;
}

.consultation__button {
  position: relative;
  bottom: -31px;
}

.consultation__error-message {
  text-align: center;
  opacity: .8;
  margin-bottom: 0;
  display: none;
}

.error .consultation__error-message {
  display: block;
}

.consultation__success-message {
  z-index: -1;
  opacity: 0;
  text-align: center;
  margin: 0;
  transition: opacity .3s 1s;
  position: absolute;
  bottom: 82px;
}

.success .consultation__success-message {
  opacity: .8;
}

.procedure {
  flex-direction: column;
  align-items: center;
  padding: 50px 30px 30px;
  display: flex;
}

@media (width <= 768px) {
  .procedure {
    align-items: flex-start;
    padding: 45px 38px 30px 44px;
  }
}

.procedure__title {
  text-align: center;
  margin: 0 0 14px;
}

@media (width <= 768px) {
  .procedure__title {
    text-align: start;
    margin: 0 0 10px;
  }
}

.procedure__subtitle {
  text-align: center;
  opacity: .8;
  margin: 0 0 49px;
}

@media (width <= 768px) {
  .procedure__subtitle {
    text-align: start;
  }
}

.procedure__list {
  justify-content: space-between;
  gap: 45px;
  width: 100%;
  max-width: 1110px;
  margin-bottom: 54px;
  display: flex;
}

@media (width <= 1200px) {
  .procedure__list {
    max-width: 930px;
  }
}

@media (width <= 768px) {
  .procedure__list {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 67px;
  }
}

.procedure__item {
  flex-direction: column;
  justify-content: start;
  width: 196px;
  display: flex;
}

@media (width <= 768px) {
  .procedure__item {
    width: initial;
  }
}

.procedure__number {
  background-color: var(--color-light-slate-blue);
  background-image: var(--gradient-orange-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 4px;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
}

.procedure__text {
  opacity: .8;
  margin: 0;
  font-weight: 300;
}

.procedure__button {
  align-self: center;
}

.about {
  flex-direction: column;
  align-items: center;
  padding: 71px 30px 30px;
  display: flex;
}

@media (width <= 768px) {
  .about {
    padding: 57px 38px 67px 44px;
  }
}

.about__title {
  text-align: center;
  opacity: .8;
  max-width: 770px;
  margin: 0 0 58px;
}

@media (width <= 768px) {
  .about__title {
    max-width: initial;
    text-align: start;
    margin: 0 0 20px;
  }
}

.about__list {
  justify-content: space-between;
  gap: 45px;
  width: 100%;
  max-width: 1110px;
  display: flex;
}

@media (width <= 1200px) {
  .about__list {
    max-width: 930px;
  }
}

@media (width <= 768px) {
  .about__list {
    flex-direction: column;
    gap: 20px;
  }
}

.about__item {
  flex-direction: column;
  width: 196px;
  display: flex;
}

@media (width <= 768px) {
  .about__item {
    width: initial;
  }
}

.about__item-title {
  background-color: var(--color-light-slate-blue);
  background-image: var(--gradient-orange-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 4px;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.about__text {
  opacity: .8;
  margin: 0;
  font-weight: 300;
}

.buy-button {
  background: url("../icon-cart.df4e6504.svg") center no-repeat, var(--gradient-red-purple);
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  font-size: 0;
  transition: transform .3s ease-in, box-shadow .3s, visibility .3s, opacity .3s;
  display: block;
  position: fixed;
  top: 0;
  left: calc(50% + 475px);
  transform: translateY(0);
  box-shadow: 0 0 57px #e4117780;
}

.buy-button:hover {
  box-shadow: 0 0 57px #e41177e6;
}

.buy-button.is-visible {
  visibility: visible;
  opacity: 1;
  transition: transform .3s ease-out, box-shadow .3s, visibility .3s, opacity .3s;
  transform: translateY(247px);
}

@media (width <= 1200px) {
  .buy-button {
    left: calc(50% + 388px);
  }
}

@media (width <= 992px) {
  .buy-button {
    left: initial;
    right: 37px;
  }
}

.banner {
  padding: 65px 30px 0;
  position: relative;
}

@media (width <= 768px) {
  .banner {
    padding: 39px 24px 30px;
  }
}

.banner__bg {
  z-index: -1;
  background: url("../bg-light-2.889ea30f.webp") calc(50% + 306px) 25px / 1284px 1284px no-repeat, url("../bg-light-1.dc3f2886.webp") calc(50% - 262px) 0 / 1368px 1101px no-repeat;
  width: 100%;
  height: 1309px;
  position: absolute;
  top: -434px;
  left: 0;
}

@media (width <= 768px) {
  .banner__bg {
    background: url("../bg-light-2.f125b290.webp") calc(50% + 105px) 98px / 1035px 1035px no-repeat, url("../bg-light-1.3ad242e4.webp") calc(50% - 80px) 0 / 900px 724px no-repeat;
    height: 1382px;
    top: -279px;
  }
}

.banner__text-block {
  background: #ffffff1a;
  border-radius: 30px;
  max-width: 860px;
  margin: 0 auto;
  padding: 44px 65px 45px;
  box-shadow: 0 20px 60px #62115080;
}

@media (width <= 1200px) {
  .banner__text-block {
    max-width: 930px;
    padding: 53px 80px;
  }
}

@media (width <= 768px) {
  .banner__text-block {
    max-width: inherit;
    padding: 64px 30px 71px;
  }
}

.banner__text {
  color: #fff;
  margin: 0;
  font-size: 20px;
  line-height: normal;
}

* {
  box-sizing: border-box;
}

:before, :after {
  box-sizing: inherit;
}

body, html {
  margin: 0;
  padding: 0;
}

body {
  color: var(--color-white-default);
  background: url("../bg-preview.01892317.webp") top / 1430px 1042px no-repeat;
  background-color: var(--color-black);
  font-family: Stem, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 20px;
}

@media (width <= 1200px) {
  body {
    background: url("../bg-preview.79141db8.webp") top / 1368px 997px no-repeat;
    background-color: var(--color-black);
  }
}

@media (width <= 768px) {
  body {
    background: url("../bg-preview-full.8136cff7.webp") 50% 90px / 814px 724px no-repeat;
    background-color: var(--color-black);
  }
}

.visually-hidden {
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.scroll-lock-ios {
  position: fixed;
  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.section-title {
  background-color: var(--color-light-slate-blue);
  background-image: var(--gradient-orange-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

@media (width <= 768px) {
  .section-title {
    font-size: 22px;
  }
}

/*# sourceMappingURL=index.d163665c.css.map */
