.preview {
  padding: 43px 0 30px;

  @media (max-width: $vp-tablet) {
    padding: 40px 44px 30px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1110px;
    margin: 0 auto;

    @media (max-width: $vp-large) {
      max-width: 930px;
    }

    @media (max-width: $vp-tablet) {
      max-width: 327px;
    }
  }

  &__title {
    width: 920px;
    margin: 0 0 30px;
    font-size: 40px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
    background-color: var(--color-light-slate-blue);
    background-image: var(--gradient-orange-purple);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: $vp-large) {
      max-width: 692px;
      margin-bottom: 14px;
    }

    @media (max-width: $vp-tablet) {
      margin-bottom: 25px;
      font-size: 36px;
      max-width: 100%;
      text-align: start;
    }
  }

  &__subtitle {
    max-width: 592px;
    margin: 0 0 449px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);

    @media (max-width: $vp-large) {
      max-width: 448px;
      margin-bottom: 391px;
    }

    @media (max-width: $vp-tablet) {
      margin-bottom: 307px;
      text-align: start;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 20px;

    @media (max-width: $vp-tablet) {
      flex-direction: column;
      gap: 25px;
    }
  }
}
