.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 71px 30px 30px;

  @media (max-width: $vp-tablet) {
    padding: 57px 38px 67px 44px;
  }

  &__title {
    max-width: 770px;
    margin: 0 0 58px;
    text-align: center;
    opacity: 0.8;

    @media (max-width: $vp-tablet) {
      max-width: initial;
      margin: 0 0 20px;
      text-align: start;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    gap: 45px;
    width: 100%;
    max-width: 1110px;

    @media (max-width: $vp-large) {
      max-width: 930px;
    }

    @media (max-width: $vp-tablet) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 196px;

    @media (max-width: $vp-tablet) {
      width: initial;
    }
  }

  &__item-title {
    width: fit-content;
    margin: 0 0 4px;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    background-color: var(--color-light-slate-blue);
    background-image: var(--gradient-orange-purple);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__text {
    margin: 0;
    font-weight: 300;
    opacity: 0.8;
  }
}
