.procedure {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px 30px;

  @media (max-width: $vp-tablet) {
    align-items: flex-start;
    padding: 45px 38px 30px 44px;
  }

  &__title {
    margin: 0 0 14px;
    text-align: center;

    @media (max-width: $vp-tablet) {
      margin: 0 0 10px;
      text-align: start;
    }
  }

  &__subtitle {
    margin: 0 0 49px;
    text-align: center;
    opacity: 0.8;

    @media (max-width: $vp-tablet) {
      text-align: start;
    }
  }

  &__list {
    display: flex;
    gap: 45px;
    justify-content: space-between;
    width: 100%;
    max-width: 1110px;
    margin-bottom: 54px;

    @media (max-width: $vp-large) {
      max-width: 930px;
    }

    @media (max-width: $vp-tablet) {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 67px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 196px;

    @media (max-width: $vp-tablet) {
      width: initial;
    }
  }

  &__number {
    width: fit-content;
    margin: 0 0 4px;
    font-size: 32px;
    font-weight: 500;
    line-height: normal;
    background-color: var(--color-light-slate-blue);
    background-image: var(--gradient-orange-purple);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__text {
    margin: 0;
    font-weight: 300;
    opacity: 0.8;
  }

  &__button {
    align-self: center;
  }
}
