.button {
  position: relative;
  display: block;
  min-width: 200px;
  padding: 6px 40px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  border-radius: 40px;

  &--primary {
    color: rgba(255, 255, 255, 0.6);
    border: none;
    background: var(--gradient-red-purple);
    box-shadow: 0px 0px 50px 0px rgba(228, 17, 119, 0.5);
    transition: box-shadow 0.3s ease;
    z-index: 1;

    &:hover,
    &:active {
      box-shadow: 0px 0px 50px 0px #f09,
        0px 5px 50px 0px rgba(255, 0, 153, 0.25);
      color: var(--color-white-default);

      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--gradient-purple-red);
      border-radius: 40px;
      transition: opacity 0.3s ease;
      z-index: -1000;
    }
  }

  &--secondary {
    color: rgba(255, 255, 255, 0.6);
    background-color: transparent;
    background-image: var(--gradient-purple-red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: box-shadow 0.3s, color 0.3s;

    &:hover,
    &:active {
      box-shadow: 0px 0px 50px 0px #f09,
        0px 5px 50px 0px rgba(255, 0, 153, 0.25);
      color: var(--color-white-default);
      -webkit-background-clip: initial;
      -webkit-text-fill-color: initial;
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-radius: 40px;
      background: var(--gradient-purple-red) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  @media (max-width: $vp-tablet) {
    min-width: 250px;
  }
}
