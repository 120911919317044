.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-black-russian);

  &__containter {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    padding: 48px 30px 129px;
    width: 100%;
    max-width: 1110px;
    max-height: 445px;

    @media (max-width: $vp-large) {
      max-width: 930px;
    }

    @media (max-width: $vp-tablet) {
      flex-wrap: nowrap;
      max-width: 1110px;
      max-height: initial;
      padding: 48px 25px 69px 39px;
    }
  }

  &__info {
    order: -2;
    width: 290px;

    @media (max-width: $vp-tablet) {
      order: initial;
      width: auto;
      max-width: 325px;
    }
  }

  &__tel {
    display: block;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.25px;
    text-decoration: none;
    color: var(--color-white-default);
  }

  &__text {
    margin: 0 0 20px;
    line-height: 22px;
    letter-spacing: 0.14px;
  }

  &__nav {
    min-height: 258px;
    margin-bottom: 26px;

    @media (max-width: $vp-tablet) {
      min-height: initial;
      margin-top: 6px;
      margin-bottom: 60px;
    }
  }

  &__nav-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__nav-link {
    text-decoration: none;
    letter-spacing: 0.14px;
    color: var(--color-spun-pearl);
    transition: color 0.3s ease;

    &:hover,
    &:active {
      color: var(--color-white-default);
    }
  }

  &__sk {
    order: -1;
    width: 120px;
    height: 34px;

    img {
      width: 100%;
      height: auto;
    }

    @media (max-width: $vp-tablet) {
      order: initial;
      margin-bottom: 20px;
    }
  }

  &__social {
    width: 226px;
    margin-bottom: 26px;

    @media (max-width: $vp-tablet) {
      width: auto;
      margin-bottom: 79px;
    }
  }

  &__social-list {
    display: flex;
  }

  &__social-item {
    &:not(:last-child) {
      margin-right: 13px;
    }
  }

  &__social-link {
    display: block;
    width: 34px;
    height: 31px;
    font-size: 0;
    border-radius: 4px;
    background-color: var(--color-tuna);
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color 0.3s ease;

    &:hover,
    &:active {
      background-color: #FF009980;
    }

    &--vk {
      background-image: url('../../img/icon-vk.svg');
      background-size: 27px 14px;
    }

    &--ok {
      background-image: url('../../img/icon-ok.svg');
      background-size: 31px 33px;
    }

    &--tg {
      background-image: url('../../img/icon-telegram.svg');
      background-size: 35px 35px;
    }
  }

  &__security {
    width: 226px;

    @media (max-width: $vp-tablet) {
      width: auto;
      max-width: 325px;
    }
  }

  &__security-text {
    font-size: 11px;
    color: var(--color-spun-pearl);
    line-height: normal;
    letter-spacing: 0.11px;

    &:not(:last-child) {
      margin-bottom: 26px;
    }

    p {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }

    a {

      text-decoration: none;
      color: var(--color-spun-pearl);
      transition: color 0.3s ease;

      &:hover,
      &:active {
        color: var(--color-white-default);
      }
    }
  }
}
