.banner {
  position: relative;
  padding: 65px 30px 0;

  @media (max-width: $vp-tablet) {
    padding: 39px 24px 30px;
  }

  &__bg {
    z-index: -1;
    position: absolute;
    left: 0;
    top: -434px;
    width: 100%;
    height: 1309px;
    background: url('../../img/bg-light-2.png?as=webp&quality=100&width=128')
        calc(50% + 306px) 25px / 1284px 1284px no-repeat,
      url('../../img/bg-light-1.png?as=webp&quality=100&width=136')
        calc(50% - 262px) 0 / 1368px 1101px no-repeat;

    @media (max-width: $vp-tablet) {
      top: -279px;
      height: 1382px;
      background: url('../../img/bg-light-2.png?as=webp&quality=80&width=103')
          calc(50% + 105px) 98px / 1035px 1035px no-repeat,
        url('../../img/bg-light-1.png?as=webp&quality=80&width=90')
          calc(50% - 80px) 0 / 900px 724px no-repeat;
    }
  }

  &__text-block {
    max-width: 860px;
    margin: 0 auto;
    padding: 44px 65px 45px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 20px 60px 0px rgba(98, 17, 80, 0.5);

    @media (max-width: $vp-large) {
      max-width: 930px;
      padding: 53px 80px;
    }

    @media (max-width: $vp-tablet) {
      max-width: inherit;
      padding: 64px 30px 71px;
    }
  }

  &__text {
    margin: 0;
    font-size: 20px;
    line-height: normal;
    color: white;
  }
}
