:root {
  --color-white-default: #ffffff;

  --color-black: #000;
  --color-black-russian: #212227;
  --color-tuna: #474b50;
  --color-spun-pearl: #a0a0a2;
  --color-light-slate-blue: #9e5fff;
  --color-froly: #fd777a;
  --color-: #ff009980;

  --gradient-orange-purple: linear-gradient(169deg, #f77 0%, #9e5fff 100%);
  --gradient-red-purple: linear-gradient(332deg, #7742e8 0%, #ff003d 100%);
  --gradient-purple-red: linear-gradient(9deg, #7742e8 0%, #ff003d 100%);
}

$vp-mobile: 375px;
$vp-tablet: 768px;
$vp-desktop: 992px;
$vp-large: 1200px;

@import '../normalize.scss';
@import 'fonts';
@import 'button';
@import 'header';
@import 'footer';
@import 'preview';
@import 'legend';
@import 'advantages';
@import 'description';
@import 'doctors';
@import 'consultation';
@import 'procedure';
@import 'about';
@import 'aside';
@import 'banner';

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

body,
html {
  margin: 0;
  padding: 0;
}

body {
  color: var(--color-white-default);
  font-family: Stem, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 20px;

  background: url('../../img/bg-preview.png?as=webp&quality=100') center top /
    1430px 1042px no-repeat;
  background-color: var(--color-black);

  @media (max-width: $vp-large) {
    background: url('../../img/bg-preview.png?as=webp&quality=90&width=1368')
      center top / 1368px 997px no-repeat;
    background-color: var(--color-black);
  }

  @media (max-width: $vp-tablet) {
    background: url('../../img/bg-preview-full.png?as=webp&quality=80&width=814')
      center 90px / 814px 724px no-repeat;
    background-color: var(--color-black);
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.scroll-lock-ios {
  position: fixed;

  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.section-title {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  background-color: var(--color-light-slate-blue);
  background-image: var(--gradient-orange-purple);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: $vp-tablet) {
    font-size: 22px;
  }
}
