.description {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 30px 30px;

  @media (max-width: $vp-tablet) {
    padding: 70px 24px 30px;
  }

  &__bg-1 {
    z-index: -1;
    position: absolute;
    left: 0;
    top: -106px;
    width: 100%;
    height: 1514px;
    background: 
      url('../../img/bg-light-5.png?as=webp&quality=100&width=128') calc(50% - 249px) 229px / 1284px 1284px no-repeat,
      url('../../img/bg-light-4.png?as=webp&quality=100&width=128') calc(50% + 234px) 0 / 1284px 1284px no-repeat;
  
    @media (max-width: $vp-tablet) {
      top: 130px;
      height: 1044px;
      background:
        url('../../img/bg-light-5-mobile.png?as=webp&quality=90&width=95') calc(50% - 84px) 240px / 953px 724px no-repeat,
        url('../../img/bg-light-4.png?as=webp&quality=90&width=104') calc(50% + 110px) 0 / 1044px 1044px no-repeat;
    }
  }

  &__bg-2 {
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: -849px;
    width: 100%;
    height: 1757px;
    background: 
      url('../../img/bg-light-6.png?as=webp&quality=100&width=175') calc(50% + 274px) 0 / 1757px 1757px no-repeat;
  
    @media (max-width: $vp-tablet) {
      bottom: -709px;
      height: 1246px;
      background:
        url('../../img/bg-light-6.png?as=webp&quality=100&width=124') calc(50% + 289px) 0 / 1246px 1246px no-repeat;
    }
  }

  &__text-block {
    max-width: 768px;
    margin-bottom: 47px;

    @media (max-width: $vp-tablet) {
      padding: 0 20px 0;
    }
  }

  &__title {
    margin: 0 0 29px;
    text-align: center;

    @media (max-width: $vp-tablet) {
      text-align: start;
      margin: 0 0 35px;
    }
  }

  &__text {
    text-align: center;
    opacity: 0.8;

    p {
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 15px;
      }
    }

    @media (max-width: $vp-tablet) {
      text-align: start;
    }
  }

  &__content {
    width: 100%;
    max-width: 1110px;

    @media (max-width: $vp-large) {
      max-width: 930px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 84px 80px 79px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 0px 20px 60px 0px rgba(98, 17, 80, 0.50);
  user-select: none;

  @media (max-width: $vp-tablet) {
    padding: 66px 20px 54px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: 27px 0 72px;

    @media (max-width: $vp-tablet) {
      margin: 13px 0 59px;
    }
  }

  &__item {
    width: 100%;
    max-height: 52px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    transition: max-height 0.5s ease;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &.is-open {
      max-height: 1000px;
    }
  }

  &__item-header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    cursor: pointer;
    background: none;
    border: none;

    &::after {
      content: '';
      position: absolute;
      top: 9px;
      right: 0;
      width: 22px;
      height: 12px;
      background-image: url('../../img/icon-arrow.svg');
      transition: transform 0.3s ease;
    }

    .is-open & {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  &__name {
    margin: 0 auto 0 0;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    color: var(--color-white-default);
    opacity: 0.8;
  }

  &__item-body {
    overflow: hidden;
    display: flex;
    max-height: 0;
    margin-bottom: 10px;
    justify-content: space-between;
    transition: max-height 0.5s ease;

    .is-open & {
      max-height: inherit;
      opacity: 1;
    }

    @media (max-width: $vp-tablet) {
      flex-direction: column;
    }
  }


  &__description {
    margin: 0 80px 0 10px;
    padding-left: 15px;
    list-style-type: "⋅  ";
    opacity: 0;
    transform-origin: top;
    transition: opacity 2s ease;

    font-weight: 200;

    .is-open & {
      opacity: 1;
    }

    li {
      opacity: 0.8;

      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }

    @media (max-width: $vp-tablet) {
      margin: 0 0 20px 7px;
    }
  }

  &__footer {
    display: flex;
    align-items: flex-end;

    @media (max-width: $vp-tablet) {
      flex-wrap: wrap;
    }
  }

  &__footer-text {
    margin: 0 auto 0 0;
    opacity: 0.8;

    @media (max-width: $vp-tablet) {
      width: 100%;
      margin-bottom: 20px;
      padding-right: 20px;
    }
  }

  &__old-price {
    margin: 0 20px 0 0;
    text-decoration: line-through;
    opacity: 0.8;
  }

  &__price {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    opacity: 0.8;
  }

  &__example {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 136px;
    color: var(--color-froly);
    opacity: 0;
    text-decoration-line: underline;
    transition: color 0.3s ease, opacity 1.5s ease;

    &:hover, &:active {
      color: var(--color-white-default);
    }

    .is-open & {
      opacity: 1;
    }

    span {
      opacity: 0.8;
    }
  }

  &__image {
    margin-bottom: 2px;
  }
}

@keyframes show{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
