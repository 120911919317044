.buy-button {
  position: fixed;
  top: 0;
  left: calc(50% + 475px);
  display: block;
  width: 80px;
  height: 80px;
  font-size: 0;
  background: url('./../../img/icon-cart.svg') center no-repeat,
    var(--gradient-red-purple);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 57px 0 rgba(228, 17, 119, 0.5);
  visibility: hidden;
  opacity: 0;
  transform: translateY(0);
  transition: transform 0.3s ease-in, box-shadow 0.3s, visibility 0.3s,
    opacity 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 57px 0 rgba(228, 17, 119, 0.9);
  }

  &.is-visible {
    transform: translateY(247px);
    transition: transform 0.3s ease-out, box-shadow 0.3s, visibility 0.3s,
      opacity 0.3s;
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: $vp-large) {
    left: calc(50% + 388px);
  }

  @media (max-width: $vp-desktop) {
    left: initial;
    right: 37px;
  }
}
