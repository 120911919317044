.consultation {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 71px 30px 244px;

  @media (max-width: $vp-tablet) {
    padding: 37px 24px 150px;
  }

  &__bg {
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 957px;
    background: 
      url('../../img/bg-light-9.png?as=webp&quality=100&width=144') calc(50% + 239px) 0 / 1449px 1101px no-repeat,
      url('../../img/bg-light-8.png?as=webp&quality=100&width=128') calc(50% - 359px) 14px / 1284px 1284px no-repeat;

    @media (max-width: $vp-tablet) {
      bottom: 0;
      height: 948px;
      background:
        url('../../img/bg-light-9-mobile.png?as=webp&quality=100&width=144') calc(50% + 229px) 0 / 1112px 1082px no-repeat,
        url('../../img/bg-light-8.png?as=webp&quality=100&width=128') calc(50% - 230px) 97px / 1284px 1284px no-repeat;
    }
  }

  &__title {
    max-width: 768px;
    margin: 0 0 65px;
    text-align: center;

    @media (max-width: $vp-tablet) {
      margin: 0 20px 67px;
      padding-right: 35px;
      text-align: start;
    }
  }
  
  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 930px;
    padding: 51px 82px 10px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 0px 20px 60px 0px rgba(98, 17, 80, 0.50);

    @media (max-width: $vp-tablet) {
      padding: 51px 30px 10px;
    }
  }

  &__input-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: opacity 0.3s ease;

    .success & {
      opacity: 0;
    }
  }
  
  &__text {
    margin: 0 0 45px;
    text-align: center;

    @media (max-width: $vp-tablet) {
      margin: 0 0 34px;
    }
  }
  
  &__input {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 405px;
    min-height: 37px;
    margin-bottom: 10px;
    padding-bottom: 8px;

    // &::before {
    //   content: '+ 7';
    //   position: absolute;
    //   top: 0px;
    //   left: 113px;
    //   font-size: 24px;
    //   line-height: normal;
    //   color: rgba(255, 255, 255, 0.4);
    // }

    @media (max-width: $vp-tablet) {
      margin-bottom: 35px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 9px;
      left: calc(50% - 102px);
      width: 204px;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.4);
    }

    input {
      position: relative;
      bottom: 1px;
      width: 213px;
      margin-bottom: 2px;
      border: none;
      font-family: Stem, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: rgba(255, 255, 255, 0.4);
      background-color: transparent;
      border: none;
      transition: color 0.7s ease;

      &:invalid {
        color: var(--color-froly);
      }

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        opacity: 0.4;
      }
    }
  }
  
  &__button {
    position: relative;
    bottom: -31px;
  }

  &__error-message {
    display: none;
    margin-bottom: 0;
    text-align: center;
    opacity: 0.8;

    .error & {
      display: block;
    }
  }

  &__success-message {
    z-index: -1;
    position: absolute;
    bottom: 82px;
    margin: 0;
    opacity: 0;
    text-align: center;
    transition: opacity 0.3s 1s ease;

    .success & {
      opacity: 0.8;
    }
  }
}
